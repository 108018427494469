<template>
  <div id="home-page">
    <div class="top">
      <div class="banner">
        <el-carousel
          :interval="bannerScroll ? 5000 : 0"
          arrow="hover"
          :indicator-position="bannerScroll ? '' : 'none'"
        >
          <el-carousel-item v-if="!storeVideo && !banner.length">
            <img src="@/assets/images/home-page-a.png" />
          </el-carousel-item>
          <el-carousel-item v-if="storeVideo">
            <video
              :src="storeVideo"
              style="width: 100%; height: 100%"
              controls
              @play="bannerScroll = false"
              @pause="bannerScroll = true"
            ></video>
          </el-carousel-item>
          <el-carousel-item v-for="(list, i) in banner" :key="i">
            <img v-lazy="list" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="introduce">
        <div class="title">
          <h2>店铺介绍</h2>
          <p class="number">
            客服电话：<span>{{ ShopForDetails.storeServicePhone }}</span>
          </p>
        </div>
        <div class="content">
          {{ ShopForDetails.storeIntroduce }}
        </div>
      </div>
    </div>
    <div class="activity" v-for="(activity, index) in Activity" :key="index">
      <div class="left" @click="goActivity(activity.id, activity.locationId)">
        <img v-lazy="activity.storePic" />
      </div>
      <div class="right">
        <goods
          v-for="(item, i) in activity.goodsList"
          :key="i"
          :information="item"
        />
      </div>
    </div>
    <goods-list :goodsInfo="ShopForDetails" />
    <!-- <recommended :goods="ShopForDetails.storeHomePageCodeItems" :flag="1" /> -->
    <recommended :goods="recommendedGoods" />
  </div>
</template>

<script>
import GoodsList from "@/components/GoodsList";
import Goods from "@/components/Goods";
import Recommended from "@/components/Recommended";

export default {
  name: "home-page",

  components: {
    Goods,
    GoodsList,
    Recommended,
  },

  data() {
    return {
      ShopForDetails: "",
      banner: [], //店铺轮播图
      Activity: "", //店铺活动列表
      recommendedGoods: "",
      storeVideo: "",
      bannerScroll: true,
    };
  },
  created() {
    this.getStore();
    this.getStoreActivity();
  },
  methods: {
    async getStore() {
      const data = await this.$API.store.getStoreHomePage({
        object: {
          storeId: this.$route.params.id,
          order: "desc",
          sortColumn: "",
        },
        pageNum: 1,
        pageSize: 25,
      });
      this.ShopForDetails = data.data.data;
      setTimeout(() => {
        if (!this.$route.query.enterpriseId) {
          this.$router.replace(
            `/Store/HomePage/${this.$route.params.id}?enterpriseId=${
              JSON.parse(sessionStorage.getItem("storeInfo")).enterpriseId
            }`
          );
        }
        this.recommendedGoods = JSON.parse(
          sessionStorage.getItem("storeInfo")
        ).storeHomePageItems.map((item) => {
          return {
            ...item,
            filePath: item.filePath.split(",")[0].endsWith(".mp4")
              ? item.filePath.split(",")[1]
              : item.filePath.split(",")[0],
          };
        });
      }, 200);
      this.ShopForDetails.storeBanner.split(",").forEach((element) => {
        if (element.endsWith(".mp4")) {
          this.storeVideo = element;
        } else {
          this.banner.push(element);
        }
      });
    },
    // 店铺活动
    async getStoreActivity() {
      const data = await this.$API.activity.getStoreActivity({
        object: {
          id: "",
          storeId: this.$route.params.id,
        },
        pageNum: 1,
        pageSize: 4,
      });
      console.log(data.data.data);
      data.data.data.forEach((item) => {
        item.platformPic = item.platformPic.split(",")[0];
        item.storePic = item.storePic.split(",")[0];
        item.goodsList = [];

        item.goodsList = item.commodityDetailsList.map(
          (activityGoods, index) => {
            return {
              ...activityGoods,
              ...item.commodityPriceDetailsList[index],
            };
          }
        );
        item.goodsList.splice(4);
        item.goodsList.forEach((goods) => {
          goods.filePath = goods.filePath.split(",")[0].endsWith(".mp4")
            ? goods.filePath.split(",")[1]
            : goods.filePath.split(",")[0];
        });
      });
      this.Activity = data.data.data;
      console.log(this.Activity);
    },
    goActivity(id, flag) {
      console.log(flag);
      if (flag === "1") {
        this.$router.push({ name: "activities-column", params: { id: id } });
      } else {
        this.$router.push({
          name: "store-activity",
          params: { activityId: id, id: this.$route.params.id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 1200px;
  height: 300px;
  display: flex;
  background: #fff;
  margin: 20px 0 30px 0;
  .banner {
    ::v-deep.el-carousel__indicator.is-active button {
      background: #fff;
      opacity: 1;
    }
    ::v-deep.el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #000000;
      opacity: 0.4;
    }
  }
  .banner,
  .introduce {
    width: 600px;
    height: 300px;
  }

  .introduce {
    padding: 16px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    h2 {
      height: 24px;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #333333;
    }
    .number {
      height: 22px;
      font-size: 16px;
      line-height: 24px;
      color: #333333;

      span {
        color: #ec382f;
      }
    }

    .content {
      font-size: 14px;
      line-height: 22px;
      color: #666666;
      height: 228px;
      padding-right: 6px;
      overflow-y: auto;
    }
  }
}
.activity {
  display: flex;
  margin-bottom: 40px;
  .left {
    width: 240px;
    height: 304px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    display: flex;
    width: 960px;
    height: 304px;
    background: #fff;
    .goods-box {
      border-right: 1px solid #f2f2f2;
    }
    .goods-box:nth-child(4) {
      border-right: none;
    }
  }
}
.recommended {
  margin-bottom: 30px;
  ::v-deep .content {
    border: 1px solid rgb(238, 238, 238);
    border-top: none;
  }
}

.el-carousel__item img {
  width: 100%;
  height: 100%;
}
</style>
<style>
#goods-list .goods {
  background: #fff;
}
.el-empty {
  padding: 100px 0;
}
</style>